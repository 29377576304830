// This hook returns an object that toggles on and off
// the analytics tabs on the homepage.
import { useContext } from 'react';
import { FlagsContext } from 'src/context';
import { useRouteAccess } from 'src/routes/routeAccess';

// the analytics tabs on the homepage.
export function useAnalyticsAccess() {
  const routeAccess = useRouteAccess();
  const { EnableNewIUHomeSubscriptionAnalytics } = useContext(FlagsContext);

  return {
    CLIENT: true,
    CLIENT_ACTIVATION: true,
    SUBSCRIPTION:
      routeAccess.allowPaymentsPage && EnableNewIUHomeSubscriptionAnalytics,
  } as const;
}
